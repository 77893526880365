export const firebaseConfig = {
  apiKey: "AIzaSyAngxMxUBbe4KpM-gp78AcCnUruMG1io0s",
  authDomain: "mtsinaisouthnassau.firebaseapp.com",
  databaseURL: "https://mtsinaisouthnassau.firebaseio.com",
  projectId: "mtsinaisouthnassau",
  storageBucket: "mtsinaisouthnassau.appspot.com",
  messagingSenderId: "577648425556",
  appId: "1:577648425556:web:de1d78814b718ffb6a0bc1"
};

export const vapidKey = `BGkoFBxaRxzEG88jeNsQQKIxfj6u99W9Q9khlzIT9_2isdVQnjbe686aN_vjONP5ao6_mDpF1NiRaNKOuTApMRc`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}